import { useAxios } from '@/hooks/useAxios'
import { convertToFormData } from '@/utils'

const request = useAxios()

// 结果单列表
export const listResultBill = (data) => {
    return request.post({
        url: '/tax/export/list_result_bill',
        data,
    })
}

// 结果单详情
export const getResultBill = (data) => {
    return request.post({
        url: '/tax/export/get_result_bill',
        data,
    })
}

// 结果单商品列表
export const listResultBillGoods = (data) => {
    return request.post({
        url: '/tax/export/list_result_bill_goods',
        data,
    })
}

// 结果单清单列表
export const listResultBillElist = (data) => {
    return request.post({
        url: '/tax/export/list_result_bill_elist',
        data,
    })
}

// 汇总结果单列表
export const listSummaryResultBill = (data) => {
    return request.post({
        url: '/tax/export/list_summary_result_bill',
        data,
    })
}

// 汇总结果单列表
export const updateNote = (data) => {
    return request.post({
        url: '/tax/export/update_note',
        data,
    })
}

// 发起汇总商品信息
export const summaryGoods = (data) => {
    return request.post({
        url: '/tax/export/summary_goods',
        data,
    })
}


// 添加进货发票
export const insertPurchaseInvoice = (data) => {
    return request.post({
        url: '/tax/import/insert_purchase_invoice',
        data,
    })
}
// 进货发票详情
export const getPurchaseInvoice = (data) => {
    return request.post({
        url: '/tax/import/get_purchase_invoice',
        data,
    })
}

// 进货发票列表
export const listPurchaseInvoice = (data) => {
    return request.post({
        url: '/tax/import/list_purchase_invoice',
        data,
    })
}
// 更新进货发票
export const updatePurchaseInvoice = (data) => {
    return request.post({
        url: '/tax/import/update_purchase_invoice',
        data,
    })
}
// 删除进货发票
export const deletePurchaseInvoice = (data) => {
    return request.post({
        url: '/tax/import/delete_purchase_invoice',
        data,
    })
}

// 删除进货发票
export const selectMatchingGoods = (data) => {
    return request.post({
        url: '/tax/import/select_matching_goods',
        data,
    })
}

// 报关单列表
export const listResultBillMatching = (data) => {
    return request.post({
        url: '/tax/management/list_result_bill_matching',
        data,
    })
}

// 报关单列表
export const getRbMatchingDetail = (data) => {
    return request.post({
        url: '/tax/management/get_rb_matching_detail',
        data,
    })
}

// 报关单列表
export const updateTaxDeclareMsg = (data) => {
    return request.post({
        url: '/tax/management/update_tax_declare_msg',
        data,
    })
}

// 报关单列表
export const listRbGoodsMatching = (data) => {
    return request.post({
        url: '/tax/management/list_rb_goods_matching',
        data,
    })
}

// 报关单列表
export const listGoodsPurchase = (data) => {
    return request.post({
        url: '/tax/management/list_goods_purchase',
        data,
    })
}

// 报关单列表
export const updateGoodsPurchase = (data) => {
    return request.post({
        url: '/tax/management/update_goods_purchase',
        data,
    })
}

//导入
export const importGoodsMatching = (id,file) => {
    return request.post({
      url: '/tax/management/import_goods_matching',
      headersType: 'multipart/form-data',
      data: convertToFormData({
        srb_id: id,
        file: file,
      }),
    })
  }

  //导入
export const importPurchaseInvoice = (file) => {
    return request.post({
      url: '/tax/import/import_purchase_invoice',
      headersType: 'multipart/form-data',
      data: convertToFormData({
        file: file,
      }),
    })
  }

// 进货发票详情
export const exportResultBillList = (data) => {
    return request.post({
        url: '/tax/export/export_result_bill_list',
        responseType: 'blob',
        data,
    })
}

// 进货发票详情
export const exportResultBillDetail = (data) => {
    return request.post({
        url: '/tax/export/export_result_bill_detail',
        responseType: 'blob',
        data,
    })
}

// 进货发票详情
export const exportGoodsMatchingList = (data) => {
    return request.post({
        url: '/tax/management/export_goods_matching_list',
        responseType: 'blob',
        data,
    })
}