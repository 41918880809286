/**
 * 转化为 FormData
 */
export const convertToFormData = (data) => {
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData
}

// 测试本地查找
export const searchData = (keyword, type, Arr) => {
  const returnArr = []
  if(type == 'verification_type' || type == 'total_amount' || type == 'unverifiedAmount'){
      Arr.map(item => {
          if(item[type] == keyword){
          returnArr.push(item)
          }
      })
  }else{
      Arr.map(item => {
          let reg = new RegExp(keyword)
          if(reg.test(item[type])){
          returnArr.push(item)
          }
      })
  }
  return returnArr
}
